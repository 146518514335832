'use client'

import { useState } from 'react'
import React from 'react';
// Adjust the import path as needed

export default function Waitlist() {
  const [email, setEmail] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [error, setError] = useState<string | null>(null)

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    setIsLoading(true)
    setError(null)
    try {
      console.log('email:', email);
      const response = await fetch('/api/waitlist', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });
      if (response.ok) {
        setIsSubmitted(true)
      } else {
        const data = await response.json();
        setError(data.message || 'An error occurred while joining the waitlist.')
      }
    } catch (error) {
      console.error('Error joining waitlist:', error)
      setError('An error occurred while joining the waitlist. Please try again later.')
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <section id="waitlist" className="py-20">
      <div className="max-w-md mx-auto text-center">
        <h2 className="text-3xl font-bold mb-4">Join the Waitlist</h2>
        <p className="text-gray-300 mb-8">Be among the first to experience the future of regulatory compliance. Sign up for early access!</p>
        {!isSubmitted ? (
          <form onSubmit={handleSubmit} className="space-y-4">
            <input
              type="email"
              placeholder="Enter your email address"
              className="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 text-white"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            {error && <p className="text-red-500">{error}</p>}
            <button
              type="submit"
              className="w-full bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 transition duration-300"
              disabled={isLoading}
            >
              {isLoading ? 'Joining...' : 'Join the Waitlist'}
            </button>
          </form>
        ) : (
          <div className="space-y-4">
            <svg className="h-16 w-16 text-green-500 mx-auto" viewBox="0 0 24 24" fill="none" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            <p className="text-xl font-medium">You&apos;re on the list!</p>
            <p className="text-gray-300">We&apos;ll notify you when we launch. Stay tuned!</p>
          </div>
        )}
      </div>
    </section>
  )
}